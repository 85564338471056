import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContent from "@sanity/block-content-to-react"
import PageTransition from "gatsby-plugin-page-transitions"
export const query = graphql`
  {
    allSanityAbout {
      edges {
        node {
          aboutImage {
            asset {
              url
            }
          }
          _rawContent
        }
      }
    }
  }
`

const About = ({ data, location }) => (
  <PageTransition
    defaultStyle={{
      transition: `opacity 250ms ease-in-out`,
      opacity: 0,
    }}
    transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 },
    }}
    transitionTime={100}
  >
    <Layout location={location}>
      <SEO title="About" />

      {data.allSanityAbout.edges.map(({ node: about }) => (
        <div id="about" key={about.toString()}>
          <div id="about__text">
            <BlockContent
              projectId="r3xca30t"
              dataset="production"
              imageOptions={{ q: 100 }}
              blocks={about._rawContent}
            />
          </div>
          <div
            id="about__image"
            style={{
              backgroundImage: "url(" + about.aboutImage.asset.url + ")",
            }}
          />
        </div>
      ))}
    </Layout>
  </PageTransition>
)

export default About
